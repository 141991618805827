<template>
    <div class="wrap">
        <div class="title">推广位管理</div>
        <div style="margin-top: 15px;">
            <el-select v-model="searchType" style="width:150px;margin-right:10px;">
                <el-option label="推广位名称" value="1" />
                <el-option label="推广位编码" value="2" />
            </el-select>
            <el-input v-model="searchResult" class="searchBtn" />
            <el-button type="primary" @click="getlistZhuankePositionsPage" icon="el-icon-search" />
        </div>
        <div style="margin-top: 15px;">
            <el-button type="primary" @click="dialogVisible = true">新建推广位</el-button>
            <el-button type="primary" @click="deletePromote" :disabled="multipleSelection.length == 0">批量删除</el-button>
        </div>
        <el-table
            ref="multipleTable"
            :data="promoteData"
            :empty-text="'找不到推广位，请新建或重新查询'"
            tooltip-effect="dark"
            style="width: 95%;margin:2% 0"
            @selection-change="handleSelectionChange"
        >
            <el-table-column
                type="selection"
                width="100"
            />
            <el-table-column
                prop="name"
                label="推广位名称"
            />
            <el-table-column
                prop="positionId"
                label="推广位编码（PID）"
            />
            <el-table-column
                prop="dbCreateTime"
                label="创建时间"
            >
                <template slot-scope="scope">
                    <span>{{ scope.row.dbCreateTime | formatTime('yyyy-MM-dd HH:mm:ss') }}</span>
                </template>
            </el-table-column>
            <el-table-column
                label="操作"
            >
                <template slot-scope="scope">
                    <el-button type="text" @click="editPromote(scope.row)">编辑</el-button>
                    <el-button type="text" @click="deletePromote(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
            :title="titletip()"
            :visible.sync="dialogVisible"
            width="30%"
            @closed="cancelChange"
        >
            <div style="display:flex;align-items:center;justify-content: flex-start">
                <el-form ref="form" label-width="100px">
                    <el-form-item label="推广类型">
                        <el-radio-group v-model="contentType" :disabled="!!this.changeId">
                            <el-radio :label="1">一般推广</el-radio>
                            <el-radio :label="100">内容媒体推广</el-radio>
                        </el-radio-group>
                        &nbsp;
                        <el-select placeholder="请选择"
                                   v-model="type"
                                   v-if="contentType === 100"
                                   :disabled="!!this.changeId"
                        >
                            <el-option
                                label="抖音"
                                :value="2"
                            >
                                抖音
                            </el-option>
                            <el-option
                                label="微博"
                                :value="3"
                            >
                                微博
                            </el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="推广位名称">
                        <el-input v-model="changePositionName" placeholder="推广位名称" />
                    </el-form-item>
                </el-form>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="cancelChange">取 消</el-button>
                <el-button type="primary" @click="submitPromote">确 定</el-button>
            </span>
        </el-dialog>
        <div class="pagination-wrap" v-if="promoteData && promoteData.length>0">
            <pagination :total="totalAmount"
                        :page-size="pageSize"
                        :current-page="pageIndex"
                        @size-change="sizeChange"
                        @current-change="currentChange"
            />
        </div>
    </div>
</template>

<script>
import pagination from '../../components/common/pagination.vue';

export default {
    name: 'goodsPromotion',
    components: {
        pagination
    },
    data() {
        return {
            pageIndex: 1,
            pageSize: 20,
            positionName: '',
            positionId: '',
            promoteData: [],
            multipleSelection: [],
            searchType: '1',
            totalAmount: 0,
            searchResult: '',
            contentType: 1,
            type: 2,
            changePositionName: '',
            changeId: '',
            // validateFlag: true,
            dialogVisible: false,
            options: [{
                value: '1',
                label: '推广位名称'
            }, {
                value: '2',
                label: '推广位编码'
            }],
            value: ''
        };
    },
    async created() {
        await this.getlistZhuankePositionsPage();
    },
    methods: {
        titletip(){
            if(this.changeId){
                return '编辑推广位';
            }
            return '新建推广位';
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },
        sizeChange(e) {
            this.pageSize = e;
            this.pageIndex = 1;
            this.getlistZhuankePositionsPage();
        },
        currentChange(e) {
            this.pageIndex = e;
            this.getlistZhuankePositionsPage();
        },
        cancelChange(){
            this.dialogVisible = false;
            this.changeId = '';
            this.type = 2;
            this.contentType = 1;
            this.changePositionName = '';
        },
        filterDeleteText(row){
            if(row.id){
                return '确定要删除推广位？';
            }
            return '确定要删除已选推广位？';
        },
        async deletePromote(row) {
            let tipText = this.filterDeleteText(row);
            this.$confirm(tipText, '删除', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async () => {
                let arr = [];
                this.multipleSelection.forEach((v) => {
                    arr.push(v.id);
                });
                if(row.id){
                    arr = [row.id];
                }
                let arrString = arr.toString();
                let data = await this.$request({
                    method: 'post',
                    url: '/api/promotion/batchInvalidPositions',
                    params: {
                        ids: arrString
                    },
                    origin: true
                });
                if(data.code === 200){
                    this.$message.success('删除成功');
                    this.getlistZhuankePositionsPage();
                }else{
                    this.$message.error(data.desc);
                }
            }).catch(() => {
                /**/
            });
        },
        async submitPromote() {
            if(!this.changePositionName) {
                this.$message.error('请填写推广位名称');
                return;
            }
            if(this.changeId){
                let data = await this.$request({
                    method: 'post',
                    url: '/api/promotion/updateZhuankePosition',
                    params: {
                        positionName: this.changePositionName,
                        id: this.changeId
                    },
                    origin: true
                });
                if(data.code === 200){
                    this.$message.success('编辑成功');
                    this.cancelChange();
                    this.getlistZhuankePositionsPage();
                }else{
                    this.$message.error(data.desc);
                }
                return;
            }
            let data = await this.$request({
                method: 'post',
                url: '/api/promotion/createZhuankePromotionPosition',
                params: {
                    positionName: this.changePositionName,
                    type: this.contentType === 1 ? 1 : this.type
                },
                origin: true
            });
            if(data.code === 200){
                this.$message.success('创建成功');
                this.cancelChange();
                this.getlistZhuankePositionsPage();
            }else{
                this.$message.error(data.desc);
            }


        },
        async editPromote(row) {
            this.dialogVisible = true;
            if (row.channel !== 1) {
                this.type = row.channel;
                this.contentType = 100;
            } else {
                this.type = 2;
                this.contentType = 1;
            }
            this.changePositionName = row.name;
            this.changeId = row.id;
        },
        async getlistZhuankePositionsPage(){
            this.positionName = '';
            this.positionId = '';

            if(`${this.searchType}` === '1'){
                this.positionName = this.searchResult;
            }
            if(`${this.searchType}` === '2'){
                this.positionId = this.searchResult;
            }
            let data = await this.$request({
                url: '/api/promotion/listZhuankePositionsPage',
                params: {
                    pageIndex: this.pageIndex,
                    pageSize: this.pageSize,
                    positionName: this.positionName,
                    positionId: this.positionId
                },
                origin: true
            });
            if(data.code === 200){
                this.promoteData = data.data.positions;
                this.totalAmount = data.data.pagination.maxRowCount;
            }else{
                this.$message.error(data.desc);
            }
        }
    }
};
</script>

<style lang="scss" scoped>
.wrap {
    .top-wrap {
        background: #fff;
        .tab-wrap {
            padding:0 120px;
            display: flex;
            justify-content: center;
        }
    }
   .pagination-wrap {
        margin-top: 20px;
        text-align: center;
        margin-bottom: 30px;
    }
     .title {
        font-size: 18px;
        line-height: 40px;
        font-weight: bold;
    }
    .searchBtn{
        width: 15%;
        margin-right: 10px;
    }
    .changeInput{
        width: 70%;
        position: relative;
        .isExit {
            position: absolute;
            color: red;
            float: left;
        }
    }

}
</style>
