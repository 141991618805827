<template>
    <div class="implemention">
        <div v-if="activityType === 'TAOBAO_RED_ORDER_NUM_REWARD'" class="title">累计核销红包订单量<span class="titleNum">{{ myOrderNum|| 0 }}</span>单</div>
        <div v-else-if="activityType === 'TMALL_FLOW_USR_CPA'" class="title">累计引流人数<span class="titleNum">{{ myOrderNum|| 0 }}</span>人</div>
        <div v-else class="title">活动玩法</div>
        <slot />
        <template v-if="zhuankeRewardType === 1">
            <template v-if=" activityType && activityType.includes('GMV_REWARD') || activityType && activityType.includes('GMV_RATE')">
                <div class="list-wrap" v-for="item in list" :key="item.level">
                    <div class="list-item">
                        <div class="reward-level">
                            销售额满{{ item.orderNumThreshold }}元，
                            奖励{{ item.rewardAmount }}{{ (activityType && activityType.includes('GMV_RATE')) ? '%' : '元' }}
                        </div>
                    </div>
                    <div class="split-line" />
                </div>
            </template>
            <template v-else>
                <div class="list-wrap" v-for="item in list" :key="item.level">
                    <div class="list-item">
                        <div class="reward-level" v-if="item.nextThreshold">
                            {{ item.orderNumThreshold }} ≤ {{ descInfo.threholdText }} ＜
                            {{ item.nextThreshold }}，{{ descInfo.rewardText }}{{ item.rewardAmount }}元
                        </div>
                        <div class="reward-level" v-else>
                            {{ descInfo.threholdText }} ≥ {{ item.orderNumThreshold }}，{{ descInfo.rewardText }}{{ item.rewardAmount }}元
                        </div>
                    </div>
                    <div class="split-line" />
                </div>
                <div class="list-wrap" v-if="activityType === 'TMALL_ZHUANKE_INVITE_NEW' || activityType === 'TAOBAO_ZHUANKE_INVITE_NEW'">非新用户当日首单，每个奖励{{ singleAmount }}元</div>
            </template>
        </template>
        <slot name="extra" />
        <div class="promotion-detail" v-if="!isTmallFlowUsrCpa">
            <div class="button-wrap" @click="jumpToPromotionList">
                推广明细
            </div>
        </div>
    </div>
</template>

<script>
export default {
    // zhuankeRewardType 为了兼容vip
    // singleAmount 猫享拉新字段
    props: ['activityType', 'list', 'zhuankeRewardType', 'singleAmount', 'myOrderNum', 'isTmallFlowUsrCpa'],
    watch: {
        isTmallFlowUsrCpa(val) {
            return val;
        }
    }, 
    computed: {
        // eslint-disable-next-line complexity
        descInfo() {
            switch (this.activityType) {
            case 'ORDER_NUM_REWARD':
            case 'TMALL_ORDER_NUM_REWARD':
            case 'TAOBAO_ORDER_NUM_REWARD':
                return {
                    threholdText: '销售订单量',
                    rewardText: '每单奖励'
                };
            case 'GMV_REWARD':
            case 'GMV_RATE':
            case 'TMALL_GMV_REWARD':
            case 'TMALL_GMV_RATE':
            case 'TAOBAO_GMV_REWARD':
            case 'TAOBAO_GMV_RATE':
                return {
                    threholdText: '累计销售额',
                    rewardText: '奖励'
                };
            case 'ZHUANKE_INVITE_NEW':
            case 'TMALL_ZHUANKE_INVITE_NEW':
            case 'TAOBAO_ZHUANKE_INVITE_NEW':
                return {
                    threholdText: '有效新客',
                    rewardText: '每个奖励'
                };
            case 'ZHUANKE_INVITE_VIP':
                return {
                    threholdText: '实付黑卡数',
                    rewardText: '每张奖励'
                };
            case 'TMALL_FLOW_USR_CPA':
                return {
                    threholdText: '引流人数',
                    rewardText: '每人奖励'
                };
            case 'TAOBAO_RED_ORDER_NUM_REWARD':
                return {
                    threholdText: '订单量',
                    rewardText: '每单奖励'
                };
            default:
                return {};
            }
        }
    },

    methods: {
        jumpToPromotionList() {
            this.$emit('jumpToPromotionList');
        }
    }
};
</script>

<style lang="scss" scoped>
.implemention {
    position: relative;
    background: #fff;
    padding: 13px 30px 10px 11px;
    .tip{
        margin: 5px 0;
    }
    .title {
        font-size: 16px;
        font-weight: 700;
        color: #333;
        margin-bottom: 10px;
    }
    .titleNum{
        font-size: 26px;
        font-weight: 700;
    }
    .list-wrap {
        margin-left: 10px;
        .list-item {
            margin-bottom: 5px;
        }
    }
    .promotion-detail {
        position: absolute;
        right: 30px;
        top: 50%;
        transform: translateY(-50%);
        .button-wrap {
            width: 140px;
            height: 32px;
            display: flex;
            justify-content: center;
            align-items: center;
            color: #666;
            border-radius: 21px;
            font-weight: 700;
            border: 1px solid #666;
            cursor: pointer;
            &:hover {
                color: #FD1E32;
                background:#FEE9EB;
                border: 1px solid #FD1E32;
            }
        }
    }
}
</style>
