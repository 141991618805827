<template>
    <div class="wrap" ref="wrap" v-loading="loading">
        <div class="title-wrap">
            <span @click="backToList" class="jump">奖励活动</span> / {{ activityName }}
        </div>
        <div class="detail-wrap">
            <div class="deatail">
                <rewardModel
                    v-if="detail"
                    :item="detail"
                    :jump-flag="false"
                    :border-flag="false"
                />
            </div>

            <reward-how :activityType="activityType"
                        :list="list"
                        :zhuankeRewardType="zhuankeRewardType"
                        :singleAmount="singleAmount"
                        @jumpToPromotionList="jumpToPromotionList"
                        :myOrderNum="myOrderNum"
                        :isTmallFlowUsrCpa="isTmallFlowUsrCpa"
            >
                <template v-if="isZhuankeInviteVipActivity" slot="default">
                    <div class="tip">
                        ·  根据您邀请的好友开通年黑卡的不同方式得相应奖励：
                    </div>
                    <div class="tip" v-if="zhuankeRewardType === 1">
                        1、好友支付279现金开通黑卡，开卡阶梯奖励如下
                    </div>
                    <div class="tip" v-if="zhuankeRewardType === 2">
                        1、好友支付279现金开通黑卡，奖励{{ fixedAmount }}元/张
                    </div>
                </template>
                <template v-if="isZhuankeInviteVipActivity" slot="extra">
                    <div class="tip">
                        2、好友通过先享后付方式开通黑卡，定额奖励{{ singleAmount }}元/张（如果好友在开卡后30天内在考拉海购无有效订单则取消奖励）
                    </div>
                    <div class="tip">
                        · 活动总最高奖励{{ rewardLimit }}元
                    </div>
                </template>
            </reward-how>
        </div>


        <div class="tab-bar" :class="{fix: isfix}">
            <el-tabs v-model="activeName" class="tab">
                <el-tab-pane v-if="!isTaobaoRedOrderNumRewardActivity && !isTmallFlowUsrCpa" label="活动商品" name="goods" />
                <el-tab-pane label="活动规则" name="rules" />
            </el-tabs>
        </div>
        <div class="reward-goods">
            <!-- 活动未开始或者已结束对用户提示 -->
            <template v-if="activeName === 'goods' && !!formatActivityGoodsTip()">
                <div class="remark">
                    <div class="left">
                        <el-alert
                            :title="formatActivityGoodsTip()"
                            type="warning"
                            show-icon
                        />
                    </div>
                </div>
            </template>
            <!--
            <div class="remark" v-if="activityType==='ZHUANKE_INVITE_NEW' && activeName === 'goods'">
                <div class="left">
                    <el-alert
                        title="以下商品仅为平台推荐的新人最爱商品，您推广平台任意商品带来的有效新客均可计入活动奖励"
                        type="warning"
                        show-icon
                    />
                </div>
            </div>
            -->

            <div v-if="isZhuankeInviteVipActivity && activeName === 'rules'">
                <el-alert title="活动奖励仅对通过您的邀请开通考拉黑卡会员的用户有效"
                          type="warning"
                          style="margin-top:10px"
                          show-icon
                />
            </div>
            <div v-if="isZhuankeInviteVipActivity && activeName === 'rules'">
                <div class="rules-wrap">
                    <div class="flow-chart-level1">
                        <div class="flow-chart-user1">您</div>
                        <div class="flow-chart-operate">分享活动给好友</div>
                    </div>
                    <div class="directionImg">
                        <img src="https://kaola-haitao.oss.kaolacdn.com/bcaf5b65-1283-479f-b358-c090cddf38f2.svg" alt="">
                    </div>

                    <div class="flow-chart-level2">
                        <div class="flow-chart-user2">好友</div>
                        <div class="flow-chart-operateList">
                            <div class="border" />
                            <div class="flow-chart-operate" style="margin-bottom:10px">通过您分享的链接开通考拉黑卡会员</div>
                            <div class="directionImg" style="margin:0">
                                <img src="https://kaola-haitao.oss.kaolacdn.com/bcaf5b65-1283-479f-b358-c090cddf38f2.svg" alt="">
                            </div>
                            <div class="flow-chart-operate">
                                {{ vipBonusSendType === 1 && !!redpacketAmount ? `获得${redpacketAmount}元赚客专属红包+丰厚开卡礼` : '获得丰厚开卡礼' }}
                            </div>
                        </div>
                    </div>
                    <div class="directionImg">
                        <img src="https://kaola-haitao.oss.kaolacdn.com/bcaf5b65-1283-479f-b358-c090cddf38f2.svg" alt="">
                    </div>
                    <div class="flow-chart-level1">
                        <div class="flow-chart-user1">您</div>
                        <div class="flow-chart-operate">获取邀请会员奖励</div>
                    </div>
                </div>
            </div>

            <div class="rules-wrap" v-show="activeName === 'rules'">{{ regulation }}</div>
            <div class="good-wrap" v-if="activeName === 'goods' && goodsType === 0">
                <el-link type="primary">
                    <router-link to="/promotion/goods">所有商品（不含虚拟）都可参与此活动，点击前往商品推广页面</router-link>
                </el-link>
            </div>
            <div class="good-wrap" v-if="activeName === 'goods' && goodsList && goodsList.length>0">
                <goods-model
                    v-for="item in goodsList"
                    :key="item.goodsId"
                    :item="item"
                />
                <div class="wrap" v-for="n in 20" :key="n" />
            </div>
            <div class="good-wrap noData"
                 v-if="activeName === 'goods' && (!goodsList || goodsList.length === 0) && !loading && goodsType !== 0"
            >
                <noData><p>暂无更多活动纪录</p></noData>
            </div>
        </div>

        <unfoldLayer />
        <rewardDialog />
        <goodsDialog />
    </div>
</template>

<script>
import rewardModel from '../../components/rewardPromotion/rewardModel.vue';
import goodsModel from '../../components/goodsPromotion/goodsModel.vue';
import unfoldLayer from '../../components/goodsPromotion/unfoldLayer.vue';
import rewardDialog from '../../components/rewardPromotion/rewardDialog.vue';
import noData from '../../components/common/noData.vue';
import goodsDialog from '../../components/goodsPromotion/goodsDialog.vue';
import RewardHow from '../../components/rewardPromotion/reward-how';
import {
    formatRewardType2Name,
    REWARD_ACTIVITY_TYPES_MAP,
    REWARD_ACTIVITY_STATUS,
    formatRewardActivityInvolveText
} from '../../utils/reward';
import { bizTypeFindByKey } from '../../utils/mapBizType.js';

export default {
    name: 'rewardDetail',
    components: {
        rewardModel,
        goodsModel,
        unfoldLayer,
        rewardDialog,
        noData,
        goodsDialog,
        'reward-how': RewardHow
    },
    data() {
        return {
            activityName: '',
            regulation: '暂无',
            detail: null,
            activityStatus: '',
            pageSize: 40,
            pageIndex: 1,
            list: [],
            goodsList: [],
            activeName: '',

            isfix: false,
            nextIndex: 2,
            remarkFlag: true,
            involve: false,
            loading: true,
            loadingGoods: false,
            scrollBox: '',
            type: '',
            appNewOnly: false,
            rewardLimit: '',
            singleAmount: '',
            activityType: '',
            isZhuankeInviteVipActivity: false,
            isTaobaoRedOrderNumRewardActivity: false,
            isTmallFlowUsrCpa: false,
            zhuankeRewardType: 1,
            vipBonusSendType: 1,
            redpacketAmount: '',
            fixedAmount: 0,
            goodsType: null,
            myOrderNum: 0
        };
    },
    async created() {
        await this.getRewardActivityDetail();

        if(this.isZhuankeInviteVipActivity || this.isTaobaoRedOrderNumRewardActivity || this.isTmallFlowUsrCpa) {
            this.activeName = 'rules';
        } else {
            this.activeName = 'goods';
            if(this.goodsType !== 0) { // 所有商品都参与活动的话 无需显示列表
                await this.getOnlineRewardActivityGoodsList();
            }
        }

        this.$nextTick(() => {
            this.loading = false;
        });
    },
    mounted() {
        this.scrollBox = this.$refs.wrap.parentNode;
        this.scrollBox.addEventListener('scroll', this.handleScroll);
    },
    beforeDestroy() {
        this.scrollBox.removeEventListener('scroll', this.handleScroll);
    },
    methods: {
        bizTypeFindByKey,
        async getRewardActivityDetail() {
            const data = await this.$request({
                url: '/api/rewardPromotion/getRewardActivityDetail',
                params: {
                    activityId: this.$route.query.activityId
                }
            });
            this.resetData(data);
        },
        resetData(data){
            this.detail = data || {};
            const rewardActivityBenefitRule = data.zhuankeRewardActivityBenefitRule || {};
            this.list = rewardActivityBenefitRule.hierarchies || [];
            this.rewardLimit = rewardActivityBenefitRule.rewardLimit;
            this.singleAmount = rewardActivityBenefitRule.singleAmount;
            this.activityName = data.activityName;
            this.activityStatus = data.activityStatus;
            this.regulation = data.regulation;
            this.involve = data.involve;
            this.appNewOnly = data.appNewOnly;
            this.activityType = data.activityType;
            this.redpacketAmount = data.redpacketAmount;
            this.fixedAmount = rewardActivityBenefitRule.fixedAmount;
            this.vipBonusSendType = data.vipBonusSendType == null ? 1 : data.vipBonusSendType;
            this.zhuankeRewardType = rewardActivityBenefitRule.zhuankeRewardType || 1;
            this.goodsType = data.goodsType;
            this.myOrderNum = data.myOrderNum;

            switch (this.activityType) {
            case REWARD_ACTIVITY_TYPES_MAP.ZHUANKE_INVITE_VIP:
                this.isZhuankeInviteVipActivity = true;
                break;
            case REWARD_ACTIVITY_TYPES_MAP.TAOBAO_RED_ORDER_NUM_REWARD:
                this.isTaobaoRedOrderNumRewardActivity = true;
                break;
            case REWARD_ACTIVITY_TYPES_MAP.TMALL_FLOW_USR_CPA:
                this.isTmallFlowUsrCpa = true;
                break;
            default:
                break;
            }

            this.list.forEach((value, index, arr) => {
                if(index <arr.length - 1) {
                    value.nextThreshold = arr[index+1].orderNumThreshold;
                }
            });
        },
        jumpToPromotionList() {
            if(this.involve) {
                this.$router.push({
                    path: '/report/reward',
                    query: {
                        activityId: this.$route.query.activityId || ''
                    }
                });
                return;
            }
            this.$message.error(`${formatRewardActivityInvolveText(this.activityType)}，赶紧推广赚奖励哦~`);
        },
        backToList() {
            this.$router.push({
                path: '/promotion/reward'
            });
        },
        async getOnlineRewardActivityGoodsList() {
            try {
                this.loadingGoods = true;
                const data = await this.$request({
                    url: '/api/rewardPromotion/getOnlineRewardActivityGoodsList',
                    params: {
                        activityId: this.$route.query.activityId,
                        pageIndex: this.pageIndex,
                        pageSize: this.pageSize
                    }
                });
                this.goodsList = [...this.goodsList, ...(data.goodsList || [])];
                this.nextIndex = data.nextIndex;
            } finally {
                this.loadingGoods = false;
            }
        },
        tabToggle() {
            this.tabFlag = !this.tabFlag;
        },
        /* eslint-disable */
        handleScroll() {
            const dom = this.$refs.wrap.parentNode;
            let scrolltop = dom.scrollTop;
            let scrollheight = dom.scrollHeight;
            let offsetheight = dom.offsetHeight;

            if(scrolltop >= 333) {
                this.isfix = true;
            }
            if(scrolltop < 333){
                this.isfix = false;
            }
            if(this.isZhuankeInviteVipActivity || this.isTaobaoRedOrderNumRewardActivity || this.isTmallFlowUsrCpa){
                this.isfix = false;
            }
            if (scrollheight - (scrolltop + offsetheight) < 100) {
                if(this.nextIndex && !this.loadingGoods) {
                    this.pageIndex = this.nextIndex;
                    this.getOnlineRewardActivityGoodsList();
                }
            }
        },
        /* eslint-disable */
        deleteRemark() {
            this.remarkFlag = false;
        },

        formatActivityGoodsTip() {
            if(this.isZhuankeInviteVipActivity || this.isTaobaoRedOrderNumRewardActivity || this.isTmallFlowUsrCpa) {
                return '';
            }
            if(this.activityStatus === REWARD_ACTIVITY_STATUS.PENDING) {
                return `活动暂未开始，订单推广将不计入活动${bizTypeFindByKey(this.detail.bizType,'tagText')}${formatRewardType2Name(this.detail)}奖励内`;
            }
            if(this.activityStatus === REWARD_ACTIVITY_STATUS.FINISHED) {
                return `活动已结束，订单推广将不计入活动${bizTypeFindByKey(this.detail.bizType,'tagText')}${formatRewardType2Name(this.detail)}奖励内`;
            }
            return '';
        }
    }
};
</script>

<style lang='scss' scoped>
.wrap {
    position: relative;
    .title-wrap {
        padding: 11px;
        background: #fff;
        .jump {
            cursor:pointer;
        }
    }
    .implemention {
        position: relative;
        background: #fff;
        padding: 13px 30px 10px 11px;
        .tip{
            margin: 5px 0;
        }
        .title {
            font-size: 16px;
            font-weight: 700;
            color: #333;
            margin-bottom: 10px;
        }
        .list-wrap {
            margin-left: 10px;
            .list-item {
                margin-bottom: 5px;
            }
        }
        .promotion-detail {
            position: absolute;
            right: 30px;
            top: 50%;
            transform: translateY(-50%);
            .button-wrap {
                width: 140px;
                height: 32px;
                display: flex;
                justify-content: center;
                align-items: center;
                color: #666;
                border-radius: 21px;
                font-weight: 700;
                border: 1px solid #666;
                cursor: pointer;
                &:hover {
                    color: #FD1E32;
                    background:#FEE9EB;
                    border: 1px solid #FD1E32;
                }
            }
        }
    }
    .tab-bar {
        margin-top: 15px;
        background: #fff;
        padding-right: 22px;
        .tab {
            position: relative;
            top: 13px;
            left: 11px;

        }

    }
    .fix{
        width: 100%;
        position: fixed;
        left: 200px;
        top: 55px;
        background: #fff;
        padding-right: 22px;

        z-index: 1003;
        .tab {
            position: relative;
            top: 0px;
            left: 11px;

        }
    }
    .reward-goods {
        .remark {
            margin-top: 5px;
        }
        .rules-wrap {
            margin-top: 10px;
            background: #fff;
            padding: 20px;
            line-height: 24px;
            color: #666666;
            white-space:pre-wrap;
            word-wrap: break-word;
            word-break: break-all;
            .flow-chart-level1{
                display: flex;
                // margin: 10px 0;
            }
            .flow-chart-level2{
                display: flex;
                // margin: 10px 0;

            }
            .flow-chart-user1{
                font-size: 14px;
                text-align: center;
                line-height: 50px;
                width: 120px;
                height: 50px;
            }
            .flow-chart-user2{
                display: inline-block;
                font-size: 14px;
                text-align: center;
                line-height: 148px;
                width: 120px;
                height: 148px;
            }
            .flow-chart-operate{
                font-size: 14px;
                text-align: center;
                line-height: 50px;
                width: 270px;
                height: 50px;
                border: 1px solid gray;
                z-index: 10;
                background-color:#fff;
                opacity: 1;
            }
            .directionImg{
                width: 270px;
                margin-left: 120px;
                text-align: center;
                margin-top: 10px;
            }
            .flow-chart-operateList{
                position: relative;
                  .border{
                      position: absolute;
                      width: 30px;
                      height: 98px;
                      border: 1px solid gray;
                      border-right: 0;
                      top: 25px;
                      left: -30px;
                      z-index: 5;

                }
            }


        }
        .good-wrap {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            margin-top: 30px;
            margin-right: -16px;
            padding-bottom: 50px;
            .wrap {
                width: 220px;
                margin-right: 16px;
            }
        }
        .noData {
            position: relative;
            margin-top: 100px;
            margin-bottom: 170px;
        }
    }
}
</style>
<style lang='scss'>
.tab-bar {
    .el-tabs__nav-wrap:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0px;
        background-color: #FFF;
        z-index: 1;
    }
    .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 15px 5px 15px 5px;
    }
}
.fix {
    .el-tabs__nav-wrap:after {
        content: "";
        position: absolute;
        left: 0;
        bottom: 0;
        width: 0;
        height: 0px;
        background-color: #FFF;
        z-index: 1;
    }
    .el-tabs__header {
        padding: 0;
        position: relative;
        margin: 15px 5px 0px 5px;
    }
}
</style>
